<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Str : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- status str -->
          <b-form-group label="status str" label-for="status_str">
            <validation-provider #default="{ errors }" name="status_str">
              <v-select
                v-model="filter.str_verified"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusStr"
                placeholder="Pilih status STR..."
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- str no -->
          <b-form-group label="Nama Dokter" label-for="orang_nama_lengkap">
            <validation-provider
              #default="{ errors }"
              name="orang_nama_lengkap"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="orang_nama_lengkap"
                  placeholder="Search Nama Dokter"
                  v-model="filter.orang_nama_lengkap"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- str no -->
          <b-form-group label="str nomor" label-for="str_nomor">
            <validation-provider #default="{ errors }" name="str_nomor">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="str_nomor"
                  placeholder="Search Nomor STR"
                  v-model="filter.str_no"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- tanggal str -->
          <b-form-group label="Tanggal Berakhir" label-for="tanggal_berakhir">
            <validation-provider #default="{ errors }" name="tanggal_berakhir">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-datepicker
                  id="tanggal_berakhir"
                  v-model="filter.str_tgl_berakhir"
                  class="mb-1"
                  placeholder="Tanggal Berakhir..."
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- tanggal str -->
          <b-form-group label="Tanggal Dibuat" label-for="tanggal_dibuat">
            <validation-provider #default="{ errors }" name="tanggal_dibuat">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-datepicker
                  id="tanggal_dibuat"
                  v-model="filter.created_at"
                  class="mb-1"
                  placeholder="Tanggal Dibuat..."
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col lg="2" md="2" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="reloadPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="modalVerifikasiData(data.item)">
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Verifikasi</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <!-- edit jadwal krip-->
    <b-modal
      id="modal-verifikasi-str"
      title="Verifikasi Data"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <table style="width: 100%">
          <tr>
            <td>File</td>
            <td>:</td>
            <td v-if="updateVerifikasiStr.str_file != null">
              <a
                v-if="
                  !updateVerifikasiStr.str_file.includes('https') &&
                    !updateVerifikasiStr.str_file.includes('base64')
                "
                :href="
                  'https://www.idijakpus.or.id/uploads/str/str_file/' +
                    updateVerifikasiStr.id +
                    '/' +
                    updateVerifikasiStr.str_file
                "
                alt="pdf"
                >Lihat File</a
              >
              <a v-else :href="updateVerifikasiStr.str_file">Lihat File</a>
            </td>
            <td v-else>{{ "data tidak di temukan" }}</td>
          </tr>
        </table>

        <br />
        <b-form-input
          id="id"
          v-model="updateVerifikasiStr.id"
          placeholder="id"
          hidden
        />
        <b-form-group label="No STR" label-for="vue-select">
          <b-form-input
            id="no_str"
            v-model="updateVerifikasiStr.str_no"
            placeholder="No STR"
          />
        </b-form-group>
        <b-form-group label="Tanggal Berakhir" label-for="vue-select">
          <b-form-datepicker
            id="example-datepicker"
            v-model="updateVerifikasiStr.str_tgl_berakhir"
            class="mb-1"
          />
        </b-form-group>
        <b-form-group label="status" label-for="vue-select">
          <v-select
            id="status"
            v-model="updateVerifikasiStr.str_verified"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionVerifikasi"
            placeholder="status.."
          />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="verifikasiData"
        >
          Simpan
        </b-button>
      </b-form>
    </b-modal>
  </b-card-code>
</template>

<script>
/* eslint-disable */
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BFormTimepicker,
  BFormSpinbutton,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    BFormTimepicker,
    BFormSpinbutton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      // lihat peserta
      pesertaCurrentPage: 1,
      pesertaRows: 0,
      pesertaPerPage: 20,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        [{ key: "orang_id.orang_nama_lengkap", label: "Nama Dokter" }],
        [{ key: "str_no", label: "Nomor STR" }],
        [{ key: "str_tgl_berakhir", label: "tanggal berakhir" }],
        [{ key: "str_verified", label: "Status Verifikasi" }],
        [{ key: "created_at", label: "Tanggal Dibuat" }],
        [{ key: "updated_at", label: "Tanggal Diupdate" }],
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */

      //lihatPeserta
      list_pesertas: [
        { key: "id", label: "Id" },
        { key: "orang_id.orang_nama_lengkap", label: "Nama Dokter" },
        "kripjadwal_id",
        "created_at",
        "updated_at",
      ],
      persertas: [],

      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],
      search: null,
      updateVerifikasiStr: {},
      optionVerifikasi: ["true", "false"],
      formatted: "",
      selected: "",
      statusStr: ["true", "false"],
      filter: {
        str_verified: null,
        str_tgl_berakhir: null,
        orang_nama_lengkap: null,
        created_at: null,
      },
    };
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getStr();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },
    reloadPage() {
      location.reload();
    },

    modalVerifikasiData(item) {
      this.updateVerifikasiStr = item;
      this.$bvModal.show("modal-verifikasi-str");
    },

    async getStr() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filter,
        };
        const { data } = await API.str.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data str");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async verifikasiData() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var ids = this.updateVerifikasiStr.id;

        let body = {
          str_no: this.updateVerifikasiStr.str_no,
          str_tgl_berakhir: this.updateVerifikasiStr.str_tgl_berakhir,
          str_verified: this.updateVerifikasiStr.str_verified,
        };
        const { data } = await API.str.verifikasi(ids, body);
        this.handleMsgSuccess("Berhasil Verifikasi Data");
        this.refreshPage();
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi");
      } finally {
        this.$bvModal.hide("modal-verifikasi-str");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getStr();
  },
  watch: {
    currentPage() {
      this.getStr();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
